// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xd3njF9cQSQjhx004ZQ4{padding-right:81px}.Xd3njF9cQSQjhx004ZQ4 .xbE1pYz8WfhD5TtAbWrg{align-items:center;display:flex;gap:15px;padding-bottom:12px}.Xd3njF9cQSQjhx004ZQ4 ._0xbAiY9Ubr0Ai6vsE1Tw{width:400px}.Xd3njF9cQSQjhx004ZQ4 .dAQan6gLOyaWeqH18jm9{padding-left:12px;width:388px}.Xd3njF9cQSQjhx004ZQ4 .dAQan6gLOyaWeqH18jm9 .pwr50njoVpUBgRpnrBnk{margin-bottom:12px}.Xd3njF9cQSQjhx004ZQ4 .dAQan6gLOyaWeqH18jm9 .pwr50njoVpUBgRpnrBnk::marker{color:var(--color-white)}.Xd3njF9cQSQjhx004ZQ4 .GI4BiQCNADDtHBclm08d{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px;margin-top:32px}.Xd3njF9cQSQjhx004ZQ4 .YSJYQWtHO3QTq5fHd3da{margin-bottom:16px}.Xd3njF9cQSQjhx004ZQ4 .oEnTajbyxbsOchoa3NA1{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/research-section/research-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,6CACE,WAAA,CAGF,4CACE,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".root {\n  padding-right: 81px;\n\n  & .header {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .description {\n    width: 400px;\n  }\n\n  & .list {\n    padding-left: 12px;\n    width: 388px;\n\n    & .listItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .divider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n    margin-top: 32px;\n  }\n\n  & .learnMore {\n    margin-bottom: 16px;\n  }\n\n  & .linkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Xd3njF9cQSQjhx004ZQ4`,
	"header": `xbE1pYz8WfhD5TtAbWrg`,
	"description": `_0xbAiY9Ubr0Ai6vsE1Tw`,
	"list": `dAQan6gLOyaWeqH18jm9`,
	"listItem": `pwr50njoVpUBgRpnrBnk`,
	"divider": `GI4BiQCNADDtHBclm08d`,
	"learnMore": `YSJYQWtHO3QTq5fHd3da`,
	"linkText": `oEnTajbyxbsOchoa3NA1`
};
export default ___CSS_LOADER_EXPORT___;
